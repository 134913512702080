// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ajankohtaista-js": () => import("./../../../src/pages/ajankohtaista.js" /* webpackChunkName: "component---src-pages-ajankohtaista-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tuottajat-js": () => import("./../../../src/pages/tuottajat.js" /* webpackChunkName: "component---src-pages-tuottajat-js" */),
  "component---src-pages-tuotteet-js": () => import("./../../../src/pages/tuotteet.js" /* webpackChunkName: "component---src-pages-tuotteet-js" */),
  "component---src-pages-yhteystiedot-js": () => import("./../../../src/pages/yhteystiedot.js" /* webpackChunkName: "component---src-pages-yhteystiedot-js" */),
  "component---src-templates-producer-js": () => import("./../../../src/templates/producer.js" /* webpackChunkName: "component---src-templates-producer-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

