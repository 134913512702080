export default {
  colors: {
    text: "#202020",
    white: "#ffffff",
    champagne: "#BD8756",
  },
  fonts: {
    body: "DINNext, sans-serif",
    heading: "DINNext, sans-serif",
    display: "'DINNextLTPro-Light', sans-serif",
  },
  fontWeights: {
    heading: "normal",
  },
  letterSpacings: {
    body: "normal",
    caps: "0.2em",
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      color: "text",
    },
    h1: { fontWeight: "heading", fontSize: [5, null, 6] },
    h2: { fontWeight: "heading" },
    h3: { fontWeight: "heading" },
    h4: { fontWeight: "heading" },
    h5: { fontWeight: "heading" },
    p: {
      mt: 0,
      mb: "1em",
    },
    a: {
      color: "champagne",
      textDecoration: "none",
    },
    strong: {
      fontFamily: "body",
    },
  },
}
